import GoogleAnalyticsService from "./GoogleAnalyticsService";
import * as Sentry from "@sentry/browser";
import { Route } from "vue-router";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Environment } from "@/enums/Config";

jest.mock("@/services/MyRentalsConfig");

const ganalytics = new GoogleAnalyticsService();

const route: Route = {
  path: "some-path",
  hash: "",
  query: {},
  params: {},
  fullPath: "",
  matched: [],
};

beforeEach(() => {
  jest.restoreAllMocks();
  spyOn(Sentry, "captureException");

  window.dataLayer = [];
});

describe("GoogleAnalyticsService", () => {
  describe("pageView", () => {
    it("should call pageView", () => {
      // given
      spyOn(console, "info");
      // when
      ganalytics.pageView(route);
      // then
      expect(console.info).toHaveBeenCalledWith(
        `[Google Analytics] trackPageview ${route.path} ${route.path}`
      );
    });

    it("should log an error when pageview fails", () => {
      // given
      spyOn(window.dataLayer, "push" as never).and.throwError("Error");
      spyOn(console, "error");
      // when
      ganalytics.pageView(route);
      // then
      expect(console.error).toHaveBeenCalledWith(
        "GoogleAnalyticsService trackObjective error"
      );
      expect(Sentry.captureException).toHaveBeenCalledWith(
        "GoogleAnalyticsService trackObjective error: Error: Error"
      );
    });

    it("should not log an error when pageview fails in production", () => {
      // given
      (window.dataLayer as any) = null;
      spyOn(console, "info");
      spyOn(console, "error");
      // when
      ganalytics.pageView(route);
      // then
      expect(console.info).not.toHaveBeenCalled();
      expect(console.error).not.toHaveBeenCalled();
    });
    it("should not console log anything if enviroment is production", () => {
      //given
      spyOn(MyRentalsConfig, "env").and.returnValue({
        ENVIRONMENT: Environment.PROD,
      });
      spyOn(console, "info");
      spyOn(console, "error");
      // when
      ganalytics.pageView(route);
      // then
      expect(console.info).not.toHaveBeenCalled();
      expect(console.error).not.toHaveBeenCalled();
    });
  });
});
